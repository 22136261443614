import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { showTradeOnApprovalBanner } from '../SbRespTradeOnApprovalPromo/SbRespTradeOnApprovalPromoBanner/sbRespTradeOnApprovalPromoHelpers';
import { pageTypeConstants } from '../../constants/pageTypeConstants';
import { getCategories, getLowestCategory, CATEGORY_LEVEL_1 } from '../../utils/categoryHelpers';
import { useServerVarsContext } from '../../global/ServerVarsContext/ServerVarsContext';
import { trackAbTestV2Variant } from 'dibs-ab-tests/exports/clientAbTestV2';
import { TRADE_QUICK_FILTERS } from '../../utils/abTestHelper';
import { useSbSelector } from '../../reducers/useSbSelector';

const SbSharedTopTileControllerComponent = ({
    itemSearch,
    viewer,
    categoryTiles,
    designTiles,
    priceTiles,
    tradeOnApprovalBanner,
}) => {
    const { isTradeQuickFiltersVariant } = useServerVarsContext();
    const isUserTypeTrade = useSbSelector(state => state.header.isUserTypeTrade);

    const showCategoryTiles = !itemSearch?.browseContentModule;
    const shouldShowPriceTiles = isUserTypeTrade && (itemSearch?.priceTiles?.length || 0) > 2;

    const appliedFilters = itemSearch?.appliedFilters || [];
    const usRegions = viewer?.regions || [];
    const shouldShowTradeOnApprovalBanner =
        isUserTypeTrade &&
        showTradeOnApprovalBanner({ appliedFilters, usRegions }) &&
        !!tradeOnApprovalBanner;

    const lowestCategory = getLowestCategory(getCategories(appliedFilters));
    const shouldShowDesignTiles =
        viewer.showCreatorDesignTiles &&
        itemSearch.pageType === pageTypeConstants.CREATOR &&
        (!lowestCategory || lowestCategory?.name === CATEGORY_LEVEL_1);

    let content = null;

    useEffect(() => {
        if (shouldShowPriceTiles) {
            trackAbTestV2Variant(TRADE_QUICK_FILTERS);
        }
    }, [shouldShowPriceTiles]);

    // price tiles are currently only on desktop
    if (priceTiles && shouldShowPriceTiles) {
        content = isTradeQuickFiltersVariant ? categoryTiles : priceTiles;
    } else if (shouldShowTradeOnApprovalBanner) {
        content = tradeOnApprovalBanner;
    } else if (shouldShowDesignTiles) {
        content = designTiles;
    } else if (showCategoryTiles) {
        content = categoryTiles;
    }

    return content;
};

SbSharedTopTileControllerComponent.propTypes = {
    itemSearch: PropTypes.object.isRequired,
    viewer: PropTypes.object.isRequired,
    categoryTiles: PropTypes.node,
    designTiles: PropTypes.node,
    tradeOnApprovalBanner: PropTypes.node,
    priceTiles: PropTypes.node,
};

export const SbSharedTopTileController = createFragmentContainer(
    SbSharedTopTileControllerComponent,
    {
        itemSearch: graphql`
            fragment SbSharedTopTileController_itemSearch on ItemSearchQueryConnection {
                pageType
                appliedFilters {
                    name
                    values {
                        code
                        urlLabel
                    }
                }
                priceTiles(isTrade: $isTrade) {
                    displayName
                }
                browseContentModule(contentModuleId: $contentModuleId) {
                    moduleType
                }
            }
        `,
        viewer: graphql`
            fragment SbSharedTopTileController_viewer on Viewer {
                showCreatorDesignTiles: featureFlag(feature: "sbCreatorDesignTiles")
                regions(
                    regionType: ITEM_LOCATION
                    regionBoundaryTypes: [METROPOLITAN, DISTRICT, DOMESTIC]
                    address: { country: "us" }
                ) @include(if: $isTrade) {
                    regionName
                    urlLabel
                }
            }
        `,
    }
);
